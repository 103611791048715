import { useEffect, useState } from "react";
import { Game, Skips } from "./mccTrackermodels"
import  Select  from "react-select";

import { ScoreboardContainer } from "./ScoreboardContainer";

//create container
export interface IGameHistoryContainerProps {
    games: Game[];
    team: string;
}


export function GameHistoryContainer({games, team}: IGameHistoryContainerProps) {

    const [years, setYears] = useState<string[]>([]);
    const [divisions, setDivisions] = useState<string[]>([]);
    const [semesters] = useState<string[]>(["Fall", "Spring", "Playoffs"]);
    const [teams, setTeams] = useState<Skips[]>([]);

    
    const [selectedYears, setSelectedYears] = useState<string[]>([]);
    const [selectedSemesters, setSelectedSemesters] = useState<string[]>([]);
    const [selectedDivisions, setSelectedDivisions] = useState<string[]>([]);
    const [selectedTeams, setSelectedTeams] = useState<Skips[]>([]);


    const handleSelectedYearChanged = (selectedOption: any) => {
        setSelectedYears(selectedOption.map((option: any) => option.value));
    }

    const handleSelectedSemesterChanged = (selectedOption: any) => {
        setSelectedSemesters(selectedOption.map((option: any) => option.value));
    }

    const handleSelectedDivisionChanged = (selectedOption: any) => {
        setSelectedDivisions(selectedOption.map((option: any) => option.value));
    }

    const handleSelectedTeamsChanged = (selectedOption: any) => {
        setSelectedTeams(selectedOption.map((option: any) => option.value));
    }

    const matchupTextHelper = (game: Game) => {
        if(game.yellow === team) {
            return "vs " + game.red;
        }
        else {
            return "vs " + game.yellow;
        }
    }

    const getElligibleGames = (): Game[] => {
        return games.filter((game: any) => {
            return (game.yellow === team || game.red === team) &&
                (selectedYears.length === 0 || selectedYears.includes(game.year)) &&
                (selectedSemesters.length === 0 || selectedSemesters.includes(game.semester)) &&
                (selectedDivisions.length === 0 || selectedDivisions.includes(game.division)) &&
                (selectedTeams.length === 0 || selectedTeams.includes(game.red) || selectedTeams.includes(game.yellow));
        });
    }

    useEffect(() => {
        const years = games.map((g: any) => g.year).filter((v: any, i: any, a: string | any[]) => a.indexOf(v) === i);
        setYears(years);
        setSelectedYears(years);
        const divisions = games.map((g: any) => g.division).filter((v: any, i: any, a: string | any[]) => a.indexOf(v) === i);
        setDivisions(divisions);
        setSelectedDivisions(divisions);
        setSelectedSemesters(semesters);
        const teams = games.map((g: any) => g.red).concat(games.map((g: any) => g.yellow)).filter((v: any, i: any, a: string | any[]) => a.indexOf(v) === i)
        setTeams(teams);
        setSelectedTeams(teams);
    }, [games]);

    return (
        <div>
<h3>Game History</h3>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <label>
                        Years:
                        <Select
                            isMulti
                            options={years.map((year: string) => ({ value: year, label: year }))}
                            onChange={handleSelectedYearChanged}
                            value={selectedYears.map((year: string) => ({ value: year, label: year }))}
                        />
                    </label>
                    <label>
                        Semesters:
                        <Select
                            isMulti
                            options={semesters.map((semester: string) => ({ value: semester, label: semester }))}
                            onChange={handleSelectedSemesterChanged}
                            value={selectedSemesters.map((semester: string) => ({ value: semester, label: semester }))}
                        />
                    </label>
                    <label>
                        Divisions:
                        <Select
                            isMulti
                            options={divisions.map((division: string) => ({ value: division, label: division }))}
                            onChange={handleSelectedDivisionChanged}
                            value={selectedDivisions.map((division: string) => ({ value: division, label: division }))}
                        />
                    </label>
                    <label>
                        Teams:
                        <Select
                            isMulti
                            options={teams.map((team: string) => ({ value: team, label: team }))}
                            onChange={handleSelectedTeamsChanged}
                            value={selectedTeams.map((team: string) => ({ value: team, label: team }))}
                        />
                    </label>
                </div>
                {getElligibleGames().map(function(game: Game) {
                    return (
                        <div>
                            <h4>{game.year} {game.semester} {game.division} {matchupTextHelper(game)}</h4>
                            <ScoreboardContainer game={game}/>
                        </div>
                    );
                })}
        </div>
    )
};